<template>
    <div id="customer-list">
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des clients" @refresh="loadData" />
            </b-card>
        </b-overlay>

    </div>
</template>
<script>

import DropDownCellRenderer from '@/views/components/ag-grid/DropDownCellRenderer.vue';
import BadgeCellRenderer from '@/views/components/ag-grid/BadgeCellRenderer.vue';

import 'animate.css'
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
export default {
    components: {
        AgGrid
    },
    data() {
        return {
            agGrid: {
                columnDefs: [{
                    headerName: "Code",
                    field: "No",
                    width: 100
                },
                {
                    headerName: "Désignation",
                    field: "Name",
                    width: 300
                },
                {
                    headerName: "Téléphone",
                    field: "Phone_No",
                    width: 100
                },
                {
                    headerName: "Address",
                    field: "Address",
                    width: 100
                },
                {
                    headerName: "E_Mail",
                    field: "E_Mail",
                    width: 100,
                    // headerClass: 'custom-header', resizeHandleClass: 'custom-resize-handle'
                },
                {
                    headerName: "Portal_Status",
                    field: "Portal_Status",
                    cellRendererFramework: 'badgeCellRenderer',
                    cellRendererParams: { componentName: "CustomerList", columnName: "Portal_Status" },
                    width: 100
                },
                {
                    headerName: '',
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'dropdownCellRenderer',
                    cellRendererParams: { componentName: "CustomerList", enableStat: true, onButtonClick: this.customerManagement },
                    sortable: false,
                    resizable: false,
                    filter: false,
                    width: 50
                },
                ],
                rows: []
            },
            showLoading: true,
        };
    },
    created() {
        this.$options.components.dropdownCellRenderer = DropDownCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        async customerManagement(rowData, action) {
            // console.log(rowData);
            // if (rowData.Portal_Status == "Active" && action == "Enable") {
            //     console.log("action not allowed !");
            //     return;
            // }
            if (rowData.Portal_Status == "Not Active" && action == "Disable") {
                console.log("action not allowed !");
                return;
            }
            if (!["Active", "Not Active"].includes(rowData.Portal_Status) && (action == "Disable" || action == "Password")) {
                console.log("action not allowed !");
                return;
            }
            this.showLoading = true;
            const VendorDto = {
                address: rowData.Address,
                email: rowData.E_Mail,
                name: rowData.Name,
                no: rowData.No,
                phoneNo: rowData.Phone_No,
                action: action,
            }
            let response = await this.$http.post("_customer", VendorDto).catch(() => {
                this.showLoading = false;
            });
            if (action == "Password") {
                this.$swal({
                    title: 'Mot de passe mis à jour avec succès',
                    text: response.data,
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    showClass: {
                        popup: 'animate__animated animate__flipInX',
                    },
                    buttonsStyling: false,
                })
            } else {
                this.agGrid.rows = response.data.result;
            }
            this.showLoading = false;
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("customer").catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
    },
};
</script>

<style lang="scss">
// .custom-header {
//     border: 1px solid #ebe9f1;
// }
</style>
